import { Component  } from "react";
import {ModalBody} from 'reactstrap'
import SubscribeForm from "../Components/SubscribeForm/SubscribeForm";
import StickyMenu from '../Components/StickyMenu/StickyMenu';

class SubscribeView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="subscribe-page-container">
                {/* Full width main picture */}
                <div className="top-banner-container">
                <div className="top-banner-image contact-page-banner"/>
                <div className="nested-title">
                    <div>Adhésion à la Croisade de la prière</div>
                </div>
                </div>
                {/* Sticky menu bar 
                <StickyMenu hiddenMenu={true}/>*/}
                <StickyMenu/>
                <ModalBody>
                    <SubscribeForm/>  
                </ModalBody>
            </div>
        )
    }
}

export default SubscribeView;