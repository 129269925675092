/*
SubscribeForm Component, based on InputForm one
*/
import React, {Component} from 'react';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './SubscribeForm.css';
import ModalSubscriberSubmit from '../ModalSubscriberSubmit/ModalSubscriberSubmit';
import InputForm3 from '../InputForm3/InputForm3';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'

class SubscribeForm extends Component{
    constructor(props){
        super(props)

        this.clearObject = {
            has_changed : false,
            country_code:"FR",
            country_calling_code:"+33",
            sir:1,
            madam:0,
            nom:"",
            prenom:"",
            addresse:"",
            codepostal:"",
            ville:"",
            tel:"",
            email:"",
            ismember:1,
            issupporter:0,
            rgpd:0,
            captcha:"",

            err_nom:[], err_prenom:[], err_addresse:[], err_codepostal:[], err_ville:[], err_email: [], err_tel: [], err_rgpd:[],err_captcha:[],
            rgpd_expended:false,
            RGPDtitle:`J'accepte les conditions`,
            RGPDtext:`En cochant cette case et en soumettant ce formulaire, j’accepte que mes données personnelles soient utilisées par La Croisade De La Prière pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire. 
            \r\nPour connaître et exercer vos droits, notamment de retrait de votre consentement vis-à-vis de l’utilisation des données collectées dans ce formulaire, veuillez consulter notre politique de confidentialité.` + `\r\n\r\nEn tant qu'association Loi 1901, nous nous conformons au Règlement Général Européen sur la Protection des données (RGPD) et nous vous assurons la confidentialité, la protection et la sécurisation de vos données personnelles.`,
            dossier:{uuidrequetes_log:""},
            debug:false
        }
        this.state = this.clearObject;

        this.rgpdCheckBoxRef = React.createRef();
        this.sirCheckBoxRef = React.createRef();
        this.madamCheckBoxRef = React.createRef();
        this.memberCheckBoxRef = React.createRef();
        this.supporterCheckBoxRef = React.createRef();
    }

    clearForm = () => {
        //Reset state
        if (!this.state.debug){
            this.clearObject = Object.assign(this.clearObject, {v1:this.randomize(0,5), v2:this.randomize(0,5)})
            this.setState(this.clearObject);
        }
    }

    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }

    componentDidMount = () =>{
        this.clearForm()
        this.handle_nom = this.handle_nom.bind(this);
        this.handle_prenom = this.handle_prenom.bind(this);

        this.handle_addresse = this.handle_addresse.bind(this);
        this.handle_codepostal = this.handle_codepostal.bind(this);
        this.handle_ville = this.handle_ville.bind(this);
        this.handle_tel = this.handle_tel.bind(this);
        this.handle_email = this.handle_email.bind(this);
        this.onChangeCheckboxRGPD = this.onChangeCheckboxRGPD.bind(this);
        this.onClickCheckboxRGPD = this.onClickCheckboxRGPD.bind(this);

        this.onChangeCheckboxSir = this.onChangeCheckboxSir.bind(this);
        this.onClickCheckboxSir = this.onClickCheckboxSir.bind(this);

        this.onChangeCheckboxMadam = this.onChangeCheckboxMadam.bind(this);        
        this.onClickCheckboxMadam = this.onClickCheckboxMadam.bind(this);

        this.onChangeCheckboxMember = this.onChangeCheckboxMember.bind(this);        
        this.onClickCheckboxMember = this.onClickCheckboxMember.bind(this);

        this.onChangeCheckboxSupporter = this.onChangeCheckboxSupporter.bind(this);        
        this.onClickCheckboxSupporter = this.onClickCheckboxSupporter.bind(this);

        if (this.state.debug){
            this.setState({
            has_changed:true,
            country_code:"FR",
            country_calling_code:"+33",
            sir:1,
            madam:0,
            nom:"Leon",
            prenom:"Camel",
            addresse:"3 rue de la commanderie",
            codepostal:"75015",
            ville:"Paris",
            tel:"+33749690652",
            email:"pmabiala@icloud.com",
            ismember:1,
            issupporter:0,
            rgpd:1});
        }
    }

    surroundedValidPhoneNumber = aPhoneNumber => {
        let phoneNumberIsValid = false;
        try{
            if (this.state.debug)
                console.log(`aPhoneNumber=${aPhoneNumber} - length:${aPhoneNumber.length} - isValidPhoneNumber:${isValidPhoneNumber(aPhoneNumber)?"true":"false"} - country_calling_code : ${this.state.country_calling_code}`)
            if ((aPhoneNumber.length == 0) || (aPhoneNumber == this.state.country_calling_code)) phoneNumberIsValid = true;
            else phoneNumberIsValid = isValidPhoneNumber(aPhoneNumber)
        }
        catch(e){
            phoneNumberIsValid = true
            console.error(`surroundedValidPhoneNumber raised $(e) with phone number ${aPhoneNumber}`)
        }
        return phoneNumberIsValid
    }

    checkValidation = (fieldName, read_only = false) => {
        
        let max = 45
        let min = 2
        
        let errorsFieldName = `err_${fieldName}`
        let nextState = this.state
        let nextErrors = []

        switch (fieldName) {
            case "nom" :     
                min = 2           
                max = 45
                
                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)
                

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "prenom" :
                min = 2
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "addresse" :
                min = 10
                max = 200

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "codepostal" :
                min = 3
                max = 10

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "ville" :
                min = 2
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break
                            
            case "tel" :
                const phone_is_required = true
                const l_sPhoneError = (this.state[fieldName] ? (this.surroundedValidPhoneNumber(this.state[fieldName]) ? "" : 'Téléphone invalide') : (phone_is_required?'Téléphone obligatoire':''))
                if (l_sPhoneError){
                    nextErrors.push(l_sPhoneError)
                }
                break


            case "email" :
                min = 5
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)

                if (!this.state[fieldName].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                    nextErrors.push(`non valide`)
                break
    

            case "captcha" :
                min = 1
                max = 2

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                
                if (parseInt(this.state[fieldName]) !== (this.state.v1 + this.state.v2))
                    nextErrors.push(`calcul faux !`)
                break

            case "rgpd" :
                if (!this.state.rgpd)
                    nextErrors.push(`obligatoire !`)
                break
            default:
                console.error(`checkValidation : champ ${fieldName} non supporté`)
        }

        if (!read_only){
            //update error field
            nextState[errorsFieldName] = nextErrors

            //set change flag
            nextState.has_changed = true

            //update internal state
            this.setState(nextState)
        }

        //Return validation predicate
        return (nextErrors.length === 0) //returns true if no error occurs
    }

    
    handle_nom = e => {
        e.preventDefault();

        this.setState({nom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_prenom = e => {
        e.preventDefault();

        this.setState({prenom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_addresse = e => {
        e.preventDefault();

        this.setState({addresse:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_codepostal = e => {
        e.preventDefault();

        this.setState({codepostal:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_ville = e => {
        e.preventDefault();

        this.setState({ville:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_tel = (newtel) => {
        //Update tel value from form field
        this.setState({did_not_change:false, tel:newtel}, () => {
            /* Check phone number right now */
            this.checkValidation("tel")
        });
    }

    handle_country = (newCountry) => {
        //Update country-code value from form field
        this.setState({
                        did_not_change:false, 
                        country_code:newCountry, 
                        country_calling_code:`+${getCountryCallingCode(newCountry)}`}, 
                        ()=>{if (this.state.debug) console.log(`tel as country changed =>${this.state.tel} / ${this.state.country_calling_code}`)});
    }

    handle_email = (e) => {
        //Update name value from form field
        this.setState({email:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    //--RGPD
    onChangeCheckboxRGPD = (e) => {
        this.setState({rgpd:e.target.checked?1:0}, ()=>{this.checkValidation("rgpd")});
    }

    onClickCheckboxRGPD = (e) => {
        this.setState(
                        {rgpd:!this.rgpdCheckBoxRef.current.checked?1:0}, 
                        ()=>{this.checkValidation("rgpd")});
    }

    //-- Sir
    onChangeCheckboxSir = (e) => {
        this.setState({sir:e.target.checked?1:0, madam:e.target.checked?0:1});
    }

    onClickCheckboxSir = (e) => {
        this.setState(
                        {sir:!this.sirCheckBoxRef.current.checked?1:0, madam:!this.sirCheckBoxRef.current.checked?0:1});
    }

    //-Madam
    onChangeCheckboxMadam = (e) => {
        this.setState({madam:e.target.checked?1:0, sir:e.target.checked?0:1});
    }

    onClickCheckboxMadam = (e) => {
        this.setState(
                        {madam:!this.madamCheckBoxRef.current.checked?1:0, sir:!this.madamCheckBoxRef.current.checked?0:1});
    }

    //-Member
    onChangeCheckboxMember = (e) => {
        this.setState({ismember:e.target.checked?1:0, issupporter:e.target.checked?0:1});
    }

    onClickCheckboxMember = (e) => {
        this.setState(
                        {ismember:!this.memberCheckBoxRef.current.checked?1:0, issupporter:!this.memberCheckBoxRef.current.checked?0:1});
    }

    //-Supporter
    onChangeCheckboxSupporter = (e) => {
        this.setState({issupporter:e.target.checked?1:0, ismember:e.target.checked?0:1});
    }

    onClickCheckboxSupporter = (e) => {
        this.setState(
                        {issupporter:!this.supporterCheckBoxRef.current.checked?1:0, ismember:!this.supporterCheckBoxRef.current.checked?0:1});
    }


    onCheckFormValidationBeforeSubmit = () => {
        //Force all fields check
        let canSubmit = this.checkValidation('nom')
        canSubmit &= this.checkValidation('prenom')
        canSubmit &= this.checkValidation('addresse')
        canSubmit &= this.checkValidation('codepostal')
        canSubmit &= this.checkValidation('ville')
        canSubmit &= this.checkValidation('tel')
        canSubmit &= this.checkValidation('email')
        canSubmit &= this.checkValidation('rgpd')

        return (canSubmit);
    }

    validateForm  = () =>{        
        let validateFormPredicate = (
            this.state.has_changed
            && this.checkValidation('nom', true)
            && this.checkValidation('prenom', true)

            && this.checkValidation('addresse', true)
            && this.checkValidation('codepostal', true)
            && this.checkValidation('ville', true)

            && this.checkValidation('tel', true)
            && this.checkValidation('email', true)
            && this.checkValidation('rgpd', true)

            
            && this.checkValidation('captcha', true)
        )
    
        return (validateFormPredicate);
    }

    formRendering = () => {
        return(
            <div>
                <ModalBody>
                    <form id="requete-form" onSubmit={this.onFormSubmitHandler}> 
                        <div className="form-group row col-md-12">
                            <div className="col-md-6">
                                <label className="col-form-label"> </label>
                                <div className="col-md-12">
                                    <input
                                        className="" 
                                        ref={this.sirCheckBoxRef} 
                                        type="checkbox" 
                                        checked={this.state.sir===1?true:false} 
                                        onChange={(e)=>{this.onChangeCheckboxSir(e);}}/>
                                        
                                        <label id="rgpd_title_label" onClick={(e)=>{this.onClickCheckboxSir(e);}}>Monsieur</label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="col-form-label"> </label>
                                <div className="col-md-12">
                                    <input
                                        className="" 
                                        ref={this.madamCheckBoxRef} 
                                        type="checkbox" 
                                        checked={this.state.madam===1?true:false} 
                                        onChange={(e)=>{this.onChangeCheckboxMadam(e);}}/>
                                        
                                        <label id="rgpd_title_label" onClick={(e)=>{this.onClickCheckboxMadam(e);}}>Madame</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row col-md-12">
                            <InputForm3 
                                label={"Prénom"} 
                                name={"prenom"} 
                                value={this.state.prenom} 
                                onChange={this.handle_prenom} 
                                type={"text"} 
                                placeholder={"Votre prénom"}
                                error={this.state.err_prenom}
                            />

                            <InputForm3 
                                label={"Nom"} 
                                name={"nom"} 
                                value={this.state.nom} 
                                onChange={this.handle_nom} 
                                type={"text"} 
                                placeholder={"Votre nom"}
                                error={this.state.err_nom}
                            />
                        </div>


                        <div className="col-md-6">
                                <label className="col-md-6 col-form-label">Adresse:</label>
                                <div className="col-md-10">
                                    <textarea
                                        className="address-text-area" 
                                        name="addresse" 
                                        value={this.state.addresse} onChange={(e)=>{this.handle_addresse(e);}} 
                                        type="text" 
                                        placeholder="Votre adresse de résidence"/>
                                    <label 
                                        className="form-error">
                                        {this.state.err_addresse.join(", ")} 
                                    </label>
                                </div>
                        </div>


                        <div className="form-group row col-md-12">
                            <InputForm3 
                                label={"Code postal"} 
                                name={"codepostal"} 
                                value={this.state.codepostal} 
                                onChange={this.handle_codepostal} 
                                type={"text"} 
                                placeholder={"Code postal de résidence"}
                                error={this.state.err_codepostal}
                            />
  
                            <InputForm3 
                                label={"Ville"} 
                                name={"ville"} 
                                value={this.state.ville} 
                                onChange={this.handle_ville} 
                                type={"text"} 
                                placeholder={"Ville de résidence"}
                                error={this.state.err_ville}
                            />
                        </div>

                        <div className="form-group row col-md-12">
                            <div className="col-md-6">
                                <label className="col-md-6 col-form-label">Téléphone :</label>
                                <div className="col-md-10">
                                    <PhoneInput
                                        smartCaret={false}
                                        placeholder="Numéro de téléphone "
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="FR"
                                        value={this.state.tel}
                                        onChange={(tel)=>{this.handle_tel(tel);}}
                                        error={() => {console.clear();console.error("error =>", this.surroundedValidPhoneNumber(this.state.tel))}}
                                        onCountryChange={(country) => {this.handle_country(country);}}
                                        />
                                </div>
                                <label className="form-error">
                                    {this.state.err_tel.join(", ")} 
                                </label>
                            </div>
                            
                        
                            <InputForm3 
                                label={"email"} 
                                name={"email"} 
                                value={this.state.email} 
                                onChange={this.handle_email} 
                                type={"text"} 
                                placeholder={"Votre adresse e-mail"}
                                error={this.state.err_email}
                            />                        
                        </div>

                        <div className="form-group row col-md-12">
                            <div className="col-md-6">
                                <label className="col-form-label"> </label>
                                <div className="col-md-12">
                                    <input
                                        className="" 
                                        ref={this.memberCheckBoxRef} 
                                        type="checkbox" 
                                        checked={this.state.ismember===1?true:false} 
                                        onChange={(e)=>{this.onChangeCheckboxMember(e);}}/>
                                        
                                        <label id="rgpd_title_label" onClick={(e)=>{this.onClickCheckboxMember(e);}}>J'adhère en tant que membre*</label>
                                </div>
                                <div className="col-md-12">
                                    <label>
                                    (*) Adhésion gratuite qui m'autorise en tant que membre à assister et prendre part aux votes des assemblées générales.
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="col-form-label"> </label>
                                <div className="col-md-12">
                                    <input
                                        className="" 
                                        ref={this.supporterCheckBoxRef} 
                                        type="checkbox" 
                                        checked={this.state.issupporter===1?true:false} 
                                        onChange={(e)=>{this.onChangeCheckboxSupporter(e);}}/>
                                        
                                        <label id="rgpd_title_label" onClick={(e)=>{this.onClickCheckboxSupporter(e);}}>Je suis sympathisant(e)**</label>
                                </div>
                                <div className="col-md-12">
                                    <label>
                                    (**) Je souhaite bénéficier des services de la Croisade sans prendre part aux votes des assemblées générales.
                                    </label>
                                </div>
                            </div>
                        </div>
                       
                        <div className="form-group row col-md-12">
                            <div className="col-md-6">
                                <label className="col-form-label"> </label>
                                <div className="col-md-12">
                                    <input
                                        className="" 
                                        ref={this.rgpdCheckBoxRef} 
                                        type="checkbox" 
                                        checked={this.state.rgpd===1?true:false} 
                                        onChange={(e)=>{this.onChangeCheckboxRGPD(e);}}/>
                                        
                                        <label id="rgpd_title_label" onClick={(e)=>{this.onClickCheckboxRGPD(e);}}>{this.state.RGPDtitle}</label>
                                        <Button onClick={()=>{this.setState({rgpd_expended:!this.state.rgpd_expended})}}>{this.state.rgpd_expended?"Réduire les RGPD":"Détailler les RGPD"}</Button>
                                        {this.state.rgpd_expended &&
                                            <label id="rgpd_text_label" onClick={(e)=>{this.onClickCheckboxRGPD(e);}}>{this.state.RGPDtext}</label>}
                                        <label 
                                            className="form-error">
                                            {this.state.err_rgpd.join(", ")} 
                                        </label>
                                </div>
                            </div>

                            <div className="form-group row col-md-6">
                            <InputForm3 
                                label={`Je ne suis pas un robot, je calcule: ${this.state.v1} + ${this.state.v2}`} 
                                name={"captcha"} 
                                value={this.state.captcha} 
                                onChange={this.handle_captcha} 
                                type={"text"} 
                                placeholder={`Combien font ${this.state.v1} + ${this.state.v2} ?`}
                                error={this.state.err_captcha}
                            />
                        </div>
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={(e) => {this.clearForm();}} hidden={!this.state.has_changed}>Tout effacer</Button>
                    <ModalSubscriberSubmit
                        color={this.validateForm()?"primary":"danger"}
                        caption={this.validateForm()?"Soumettre mon adhésion":"Contrôler ma saisie !"}
                        className="modal-full-screen"
                        onCheckFormValidationBeforeSubmit={this.onCheckFormValidationBeforeSubmit}
                        data = {this.state}
                        onFailed={()=>{console.log("Submission failed...")}}
                        onSuccess={()=>{console.log("Submission succedeed..."); this.clearForm();}} 
                        disabled={false}/>
                </ModalFooter>
            </div>
        )
    }

    render = () => {
        return (
            <div>
                {this.state.debug && <h1 style={{color:'red'}}>DEBUGGING MODE</h1>}
                {this.formRendering()}
            </div>
            );
    }
}

export default SubscribeForm;