/*
SocialShareView
*/

import React, {Component, useEffect, useState} from 'react';
import { Button } from 'reactstrap';
import SocialShare from '../Components/SocialShare/SocialShare';
import StickyMenu from '../Components/StickyMenu/StickyMenu';

const DonateView = () => {
    //rendering
    return (
        <div className="donate-page-container">
                {/* Full width main picture */}
                <div className="top-banner-container">
                    <div className="top-banner-image donate-page-banner"/>
                    <div className="nested-title">
                        <div>Faire un don...</div>
                        <a class="rounded-button transparent-button" href="#/contact-us">contactez-nous</a>
                    </div>
                </div>
                {/* Sticky menu bar */}
                <StickyMenu/>
                <div className="body-page-container">
                {/* Nous avons besoin de vous */}
                <div className="events-fluid-container">
                    <div className="events-container">
                        <div className="events-title">Votre aide est précieuse pour soutenir la Croisade De La Prière dans sa mission.</div>
                        <div className="page-introduction">
                            <p>Il y a une tâche importante à accomplir et « Ceux qui se réjouissent d'avoir la précieuse lumière de la vérité devraient désirer
                            ardemment la répandre en tous lieux. »</p>
                            <p>« Le monde est là qui attend d'être averti. Cette tâche nous a été confiée. Nous devons être attachés à la vérité quoi qu'il puisse
                            nous en coûter. [...] Une grande œuvre doit être réalisée en un court laps de temps. Nous devons avoir une claire notion de notre
                            travail et l'accomplir avec fidélité. [...] Le devoir de tout chrétien est de s'engager dans la croisade contre Satan, en brandissant
                            la bannière ensanglantée de la croix du <b>Christ</b>.</p>
                            <p>
                            <b>La Croisade De La Prière</b> a une double vocation : le ministère de l’intercession et celui de l’évangélisation. Elle soutient l’Église
                            dans sa mission à travers différentes actions spécifiques, des œuvres de bienfaisance, des formations, des séminaires, des études
                            bibliques, des conférences, etc. Vous l’avez compris, la liste n’est pas exhaustive et les possibilités de servir <b>le Seigneur</b> sont
                            innombrables. Nous sommes persuadés que <b>Dieu</b> utilisera des moyens simples, de manière inattendue, pour atteindre le monde.<br/>
                            Voulez-vous participer à cette grande et belle aventure avec nous ?</p>
                            <p>
                            Nous vous invitons à soutenir, financièrement, selon vos possibilités, le ou les projets de la liste ci-dessous pour l’avancement
                            de l’œuvre de Dieu. Que chacun donne comme il l’a résolu en son cœur, sans tristesse ni contrainte ; car Dieu aime celui qui
                            donne avec joie. <i>(2 Cor 9.7)</i> Vous trouverez ci-dessous plusieurs options pour soutenir ce ministère. Ensemble, nous ferons des
                            exploits !</p>

                            <p><i>Le trésorier</i></p>
                        </div>
                        <div className='events-title'>Comment soutenir ?</div>
                        <div className="donate-columns">
                            <div className='donate-item by-cheque'>
                                Vous pouvez envoyer un chèque à l’ordre de la Croisade De La Prière à l’adresse suivante&nbsp;:
                                <div className='emphasis'>151 rue de Savoie, 93290
                                Tremblay-en-France</div>
                            </div> 
                            <div className='donate-item by-virement'>
                                Vous pouvez effectuer un virement sur le compte de La Croisade De La Prière&nbsp;:
                                <div className='emphasis'>IBAN : FR64 3000 2014 4900 0007 3088 D64</div>
                            </div> 
                            <div className='donate-item by-internet'>
                                Vous pouvez aussi passer par notre intermédiaire en ligne&nbsp;:
                                
                                <div className='emphasis'>
                                    <a className='hello-asso' target="_blank" href="https://www.helloasso.com/associations/la-croisade-de-la-priere/formulaires/1"></a>
                                </div>
                            </div>
                        </div>               
                    </div>
                </div>
            </div>
        </div>
        );
}

export default DonateView;