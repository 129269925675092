import React, { Component } from 'react'
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {Route, NavLink, Link, Switch, BrowserRouter, useHistory} from "react-router-dom"
import axios from 'axios'

class EventsPage extends Component{
    constructor(props){
        super(props)
        this.state={event_list:null};
    }

    componentDidMount = async () => {
        //Call micro-service
        let service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                                ?'http://localhost:5000/event-list'
                                :'https://thcc-service-debug.neuronal.bible/event-list'//Debugging on remote server dedicated xxx-debug subdomain
                                //:'https://thcc-service-prod.neuronal.bible/event-list';

        //Let us fetch events from remote database
        let events = await axios.get(service_db_url);
        this.setState({event_list : events.data.items});
        console.log("events=>", events);
    }

    staticRender = () => {
        return (
            <div className="events-columns-container">
                <div className="event-column">
                    <div className="event-background-image img1">
                        <div className="event-override-image-container">
                            <div className="event-name">Connaître Dieu</div>
                            <div className="event-description">
                            Nous sommes heureux de vous convier à notre nouvelle soirée de prière, qui aura lieu...</div>
                        </div>
                    </div>
                </div>

                <div className="event-column">
                    <div className="event-background-image img2">
                        <div className="event-override-image-container">
                            <div className="event-name">Fiabilité de la Bible</div>
                            <div className="event-description">
                            Nous sommes heureux de vous convier à notre nouvelle soirée de prière, qui aura lieu...</div>
                        </div>
                    </div>
                </div>

                <div className="event-column">
                    <div className="event-background-image img3">
                        <div className="event-override-image-container">
                            <div className="event-name">Toujours inclinés</div>
                            <div className="event-description">
                            Nous sommes heureux de vous convier à notre nouvelle soirée de prière, qui aura lieu...</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    specialEventRender = () => {
        return (
            <div className="events-columns-container">
                <div className="event-column">
                    <div className="event-background-image img-special-event">
                        <div className="event-override-image-container">
                            <div className="event-name">Intercessions quotidiennes</div>
                            <div className="event-description">
                            Nous sommes heureux de vous convier à nos cessions d'Intercessions de prière, à 6h tous les matins et a 20h30 les dimanches et mardis soirs.</div>
                        </div>
                    </div>
                </div>

                <div className="event-column">
                    <div className="event-background-image img-zoom-event">
                        <div className="event-override-image-container">
                            <div className="event-name">Prochain culte Zoom</div>
                            <div className="event-description">
                            Venez louer le Seigneur, Samedi 30 avril 2022 à 18h ...</div>
                        </div>
                    </div>
                </div>
                
                <div className="event-column">
                    <div className="event-background-image img-school-event">
                        <div className="event-override-image-container">
                            <div className="event-name">Etudes bibliques</div>
                            <div className="event-description">
                            Vous avez faim , soif de la Parole de <strong>Dieu</strong>. Vous êtes en questionnement&nbsp;?<br/>Venez assistez aux études bibliques du mardi soir, prodiguées par notre pasteur <i>Samuel Saint-Elie.</i></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    edificationEventRender = () => {
        return (
            <div className="events-columns-container">
                <div className="event-column">
                    <div className="event-background-image img-school-event">
                        <div className="event-override-image-container">
                            <div className="event-name">Etudes bibliques</div>
                            <div className="event-description">
                            Vous avez faim , soif de la Parole de <strong>Dieu</strong>. Vous êtes en questionnement&nbsp;?<br/>Venez assistez aux études bibliques du mardi soir, prodiguées par notre pasteur <i>Samuel Saint-Elie.</i></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    downloadEventRender = () => {
        return (
            <div className="events-columns-container">

{/*
                <a href="http://localhost:3000/download/fascicule_dec_2021.pdf">DOWNLOAD</a>
                <Button href={`${false ? "https://www.lacroisadedelapriere.com/download/fascicule_dec_2021.pdf" : "http://localhost:3000/download/fascicule_dec_2021.pdf"}`}>DOWNLOAD</Button>
                <Link to="http://localhost:3000/download/fascicule_dec_2021.pdf" target="_blank" download>Fascicule</Link>
                <Link to="download/fascicule_dec_2021.pdf" target="_blank" download>Fascicule</Link>
                <Link to="/download/fascicule_dec_2021.pdf" target="_blank" download>Download</Link>
                >*/}                
                <div className="event-column" onClick={()=>{window.location.href =`${true ? "https://www.lacroisadedelapriere.com/download/fascicule_dec_2021.pdf" : "http://localhost:3000/download/fascicule_dec_2021.pdf"}`}}>                
                    <div className="event-background-image img-il-vient">
                        <div className="event-override-image-container">
                            <div className="event-name">Voici, il vient !</div>
                            <div className="event-description">
                            Téléchargez le fascicule de Trois jours de jeûne et de prières...</div>
                        </div>
                    </div>
                </div>
{/*
                <div className="event-column" onClick={()=>{window.location.href =`${true ? "https://www.lacroisadedelapriere.com/download/fascicule_jan_2022.pdf" : "http://localhost:3000/download/fascicule_jan_2022.pdf"}`}}>                
                    <div className="event-background-image img-il-vient">
                        <div className="event-override-image-container">
                            <div className="event-name">En quête du plan de Dieu</div>
                            <div className="event-description">
                            Téléchargez le fascicule de Trois jours de jeûne et de prières...</div>
                        </div>
                    </div>
                </div>*/}
</div>
        )
    }

    dynamicRender = () => {
        let events_block_div = null;

        console.log("this.state.event_list =>", this.state.event_list)
        if (!((this.state.event_list != null) && (this.state.event_list.length > 0))){
            events_block_div = this.staticRender();
        }
        else{
            events_block_div = this.state.event_list.map((eventItem,i)=>{
                return  <div key={i} className="event-column">
                            <img alt={i} src={eventItem.logo} width="100%"></img>
                            <div className="event-name">{eventItem.nom}</div>
                            <div className="event-description">{eventItem.description_activite}</div>
                            <div>
                            <a href>En savoir plus...</a>
                            </div>
                            <Button>Commandez maintenant !</Button>
                        </div>});
                }

        console.log("table_body =>", events_block_div);
        
        return(
            <div className="events-columns-container">
                {events_block_div}
            </div>
            );
    }

    render = () => {
        if (this.props.category == "edification"){
        return(
            this.edificationEventRender()
        )}

        if (this.props.category == "special"){
            return(
                this.specialEventRender()
                //this.dynamicRender() 
            )
    
        }
        else{
            return(this.downloadEventRender())
        }
    }
} 

export default EventsPage;