import React, { Component, CreateRef } from "react";
import axios from "axios";
import "./Quiz.css"
//import { Button } from "@material-ui/core";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class Quiz extends Component{

    constructor (props){
        super(props)
        this.state = {remote_quizz_form_id:1,quiz:[],show_all_responses:false}
    }

    componentDidMount = () => {
        //micro-services url
        /*
        this.rbase_service_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                                ?'http://localhost:4000'
                                :'https://rbase-service.neuronal.bible';*/

        this.rbase_service_url = 'https://rbase-service.neuronal.bible';//Force remote rbase 
        //Initial Quiz content loading
        this.loadQuizData()
    }

    loadQuizData = async () => {
        let rBaseServiceResult = await axios.get(`${this.rbase_service_url}/form-load-data?idforms=${this.state.remote_quizz_form_id}`)
        console.clear();
        console.log("rBaseServiceResult:", rBaseServiceResult, "err_code:",rBaseServiceResult.data.err_code)
        if ((rBaseServiceResult.data.state == true) && (rBaseServiceResult.data.items.length > 0)){
            this.setState(
                {quiz:rBaseServiceResult.data.items}, ()=>{console.log("Quiz state:",this.state)}
            )
        }
    }

    hide = currentclassName => {
        return `${currentclassName} hide`
    } 

    show = currentclassName => {
        return currentclassName.replace('hide', '');
    } 

    onSubmitUserResponse = (input_ref, auto_rbase_id, div_user_response_ref, div_user_retry_ref, div_user_congrat_ref) => {
        console.log(`ref:${input_ref.current.value} - QuizId:${auto_rbase_id}`);
        
        //Check user response
        div_user_response_ref.current.className=this.hide(div_user_response_ref.current.className); //Hide user input anyway
        if (input_ref.current.value.toLowerCase().trim() == this.state.quiz[auto_rbase_id-1].reponse.toLowerCase().trim()){
           //Good anwser
           div_user_congrat_ref.current.className= this.show(div_user_congrat_ref.current.className);//Display congratz div
        }
        else{
            //Bad answer
            div_user_retry_ref.current.className = this.show(div_user_retry_ref.current.className);//Display retry div
        }
    }

    onUserRetry = (div_user_response_ref, div_user_retry_ref) => {
        //Display input back
        div_user_response_ref.current.className = this.show(div_user_response_ref.current.className);

        //Hide Retry Div
        div_user_retry_ref.current.className = this.hide(div_user_retry_ref.current.className);
    }

    onUserRequestSolutions = () => {
        this.setState({show_all_responses:true})
    }

    render = () => {
        return(
            <>
                <div className="quizz-container">
                    {this.state.quiz.length > 0 &&                        
                        <>
                        {this.state.quiz.map(
                            quizItem => {
                                let input_ref = React.createRef();
                                let div_user_response_ref = React.createRef();
                                let div_user_retry_ref = React.createRef();
                                let div_user_congrat_ref = React.createRef();

                                return(
                                    <div className="quizz-component-item">
                                        <p className="quiz-title">{`Quiz sur la Bible n°${quizItem.auto_rbase_id}`}</p>
                                        <div className="quiz-question">
                                            <p className="question-text">{`${quizItem.question} ?`}</p>
                                        </div>

                                        <div>
                                            <div ref={div_user_response_ref} className="user-response"> 
                                                <input ref={input_ref} type="text" placeholder="Votre réponse"/>
                                                <p></p>
                                                <Button color="primary" class="rounded-button transparent-button" onClick={()=>{
                                                    this.onSubmitUserResponse(input_ref, quizItem.auto_rbase_id, div_user_response_ref, div_user_retry_ref, div_user_congrat_ref);}}>Soumettre...</Button>
                                            </div>

                                            <div ref={div_user_retry_ref} className="user-retry hide"> 
                                                {/*<p>{`La bonne réponse commence par `}<span className="quiz-indice">{quizItem.reponse[0]}</span></p>*/}
                                                <p>{`Indice : `}<span className="quiz-indice">{quizItem.indicedereponse}</span></p>
                                                <Button color="primary" onClick={()=>{this.onUserRetry(div_user_response_ref, div_user_retry_ref)}}>Réessayer...</Button>
                                            </div>

                                            <div ref={div_user_congrat_ref} className="quiz-answer hide">
                                                <p className="question-text">{`Gloire à Dieu ! La bonne réponse est : `}<span className="good-answer">{quizItem.reponse}</span></p>
                                            </div>

                                            {this.state.show_all_responses &&
                                            <div className="quiz-solution-container">
                                                <p className="quiz-solution-text">Solution:{`${quizItem.reponse}`}</p>
                                                <i>{quizItem.referencebiblique}</i>
                                            </div>}
                                        </div>
                                    </div>
                                )})}
                        </>
                    }                   
                </div>
                <div className="quizz-solution-toolbar">
                        <a class="rounded-button transparent-button border-black" onClick={()=>{this.onUserRequestSolutions();}}>Voir les solutions</a>
                </div>
            </>
        )
    }
}

export default Quiz;