import logo from './logo.svg';
import './App.css';
import { Route, NavLink, HashRouter } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink as NavLink2, Container } from 'reactstrap';
import { Component } from 'react';
import FooterPage from './Components/FooterPage/FooterPage';
import BiblePageView from './Views/BiblePageView'
import ContactView from './Views/ContactView';
import SocialShareView from './Views/SocialShareView';
import DownloadView from './Views/DownloadView';
import SubscribeView from './Views/SubscribeView';
import QuizView from './Views/QuizView';
import DonateView from './Views/DonateView';
import {globals} from "./Components/Globals/Globals"

import jlrpt_logo from './Assets/img/favicon_package_v0.16/favicon-32x32.png'
import '../src/Components/HomePage/HomePage.css'

class App extends Component {
  constructor(props){
    super(props);
    this.state = {collapsed:true}
  }

  toggleNavbar = () => this.setState({collapsed:!this.state.collapsed});

  render = () =>{
    return (
      <HashRouter>
        {/* Router page content */}
        <div className="content">
          <Route exact path = "/" component={HomePage} />
          <Route path = "/bibleread" component={BiblePageView} />
          <Route path = "/contact-us" component={ContactView} />
          <Route path = "/promote-us" component={SocialShareView} />
          <Route path = "/download" component={DownloadView} />
          <Route path = "/subscription" component={SubscribeView}/>
          {globals.parameters.quiz_enabled &&
          <Route path = "/quiz" component={QuizView}/>}
          {globals.parameters.donate_enabled &&
          <Route path = "/donate" component={DonateView}/>}
        </div>
        {/* Footer */}
        <FooterPage/>
      </HashRouter>
    );
  }
}

export default App;
