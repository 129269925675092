import React from "react"
import BibleForce from "../Components/BibleForce/BibleForce"
import StickyMenu from "../Components/StickyMenu/StickyMenu"

const BiblePageView = () => {
    return(
        <div className="bible-page-container">
            {/* Full width main picture */}
            <div className="top-banner-container">
                <div className="top-banner-image bible-page-banner"/>
                <div className="nested-title">
                    <div>Etudions ensemble la Parole de Dieu...</div>
                    <a class="rounded-button transparent-button" href="#/contact-us">contactez-nous</a>
                </div>
            </div>
            {/* Sticky menu bar */}
            <StickyMenu/>
            <BibleForce/>
        </div>
    )
}

export default BiblePageView;