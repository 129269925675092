/*
SocialShareView
*/

import React, {Component, useEffect, useState} from 'react';
import SocialShare from '../Components/SocialShare/SocialShare';
import StickyMenu from '../Components/StickyMenu/StickyMenu';

const SocialShareView = () => {
    //rendering
    return (
        <div className="social-page-container">
                {/* Full width main picture */}
                <div className="top-banner-container">
                    <div className="top-banner-image social-page-banner"/>
                    <div className="nested-title">
                        <div>Réseaux sociaux...</div>
                        <a class="rounded-button transparent-button" href="#/contact-us">contactez-nous</a>
                    </div>
                </div>
                {/* Sticky menu bar */}
                <StickyMenu/>
                <div className="body-page-container">
                {/* Evèvements à télécharger */}
                <div className="events-fluid-container">
                    <div className="events-container">
                        <div className="events-title">Partager avec une connaissance</div>
                        <div className="page-introduction">Vous souhaitez faire connaître ce site à vos amis.<br/>Merci de cliquer l'un des réseaux sociaux ci-dessous.</div>
                        <div className="buttons-panel">
                            <SocialShare />
                        </div>               
                    </div>
                </div>
            </div>
        </div>
        );
}

export default SocialShareView;