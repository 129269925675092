import React, { Component } from "react";
import axios from "axios";
import GMlookupCombo3 from '../GMlookupCombo3/GMlookupCombo3'
import "./BibleForce.css"

class BibleForce extends Component{

    constructor (props){
        super(props)
        this.state = {bookId:1, chapterId:1, bible_text:[], bookName:"Genèse"}
    }

    componentDidMount = () => {
        this.localURL = "http://localhost:5001/bible-text" 
        this.remoteURL = "https://bible-service.lacroisadedelapriere.org/bible-text"
        this.BibleServiceUrl = (["localhost", "127.0.0.1"].includes(window.location.hostname))?this.localURL:this.remoteURL
        //Initial Bible content loading
        this.loadBibleContent()
    }

    loadBibleContent = async () => {
        let bibleServiceResult = await axios.post(`${this.BibleServiceUrl}`, {bookId:this.state.bookId, chapterId:this.state.chapterId})
        console.log("bibleServiceResult:", bibleServiceResult, "err_code:",bibleServiceResult.data.err_code)
        if ((bibleServiceResult.data.state == true) && (bibleServiceResult.data.items.length > 0)){
            this.setState(
                {bible_text:bibleServiceResult.data.items}, ()=>{console.log("Bible state:",this.state)}
            )
        }
    }

    render = () => {
        return(<div className="bible-force">
                <div className="bible-title">
                    <h2>La Bible Segond 1910</h2>
                
                
                    <div className="bible-nav-bar"> 
                            <div className="bible-bar-combo">
                                <GMlookupCombo3
                                    keyFieldName = "book_id" //Field name on wich selection is made on table
                                    fieldName = "name" //Field name displayed on list
                                    activeSelectionKeyValue={this.state.bookId}  //Active selection to be displayed by default. Not used on creation (optionnal)
                                    searchEnabled = {false} //Display an incremental input search field
                                    localURL = 'http://localhost:5001/bible-books' //micro service local with returns data set
                                    remoteURL = 'https://bible-service.lacroisadedelapriere.org/bible-books' //Production micro service wich returns data set
                                    OnSelect = {(itemsObjectItem)=>{console.log("itemsObjectItem:", itemsObjectItem); this.setState({bookId:itemsObjectItem.book_id, chapterId:1, bookName:itemsObjectItem.name}, ()=>{this.loadBibleContent()})}}
                                    /> {/*On selection handler*/}
                            </div>

                            <div className="bible-bar-combo">
                                <GMlookupCombo3
                                    keyFieldName = "chapter_id"
                                    fieldName = "chapter_id"
                                    activeSelectionKeyValue={this.state.chapterId}  //Active selection to be displayed by default. Not used on creation (optionnal)
                                    getLookupParameterName = "bookId" //Parameter name that will be use on GET request 
                                    getLookupParameterValue = {this.state.bookId}
                                    
                                    searchEnabled = {false}
                                    localURL = 'http://localhost:5001/bible-chapters'
                                    remoteURL = 'https://bible-service.lacroisadedelapriere.org/bible-chapters'
                                    OnSelect = {(itemsObjectItem)=>{console.log("itemsObjectItem:", itemsObjectItem); this.setState({chapterId:itemsObjectItem.chapter_id}, ()=>{this.loadBibleContent()})}}
                                    />
                            </div>
                    </div>
                </div>

                <div className="bible-bar-active-selection">
                    <div>
                        <span>{this.state.bookName}</span>
                        <span>&nbsp;</span>
                        <span>{this.state.chapterId}</span>
                    </div>
                </div>


                {this.state.bible_text.length > 0 &&
                    <div className="bible-component">
                        <div className="bible-text-container">
                            {this.state.bible_text.map(
                                verseItem => {
                                    return(
                                        <>
                                            <span className="verse-number">{verseItem.verse_id}</span>
                                            <span className="verse-text">{verseItem.text}</span>
                                        </>
                                    )})}
                        </div>
                    </div>}
            </div>
        )
    }
}

export default BibleForce;