/*
QuizView
*/

import React, {Component, useEffect, useState} from 'react';
import Quiz from '../Components/Quiz/Quiz';
import StickyMenu from '../Components/StickyMenu/StickyMenu';

const QuizView = () => {
    //rendering
    return (
        <div className="quiz-page-container">
                {/* Full width main picture */}
                <div className="top-banner-container">
                    <div className="top-banner-image quiz-page-banner"/>
                    <div className="nested-title">
                        <div>Quiz biblique...</div>
                        <a class="rounded-button transparent-button" href="#/contact-us">contactez-nous</a>
                    </div>
                </div>
                {/* Sticky menu bar */}
                <StickyMenu/>
                <div className="body-page-container">
                {/* Evèvements à télécharger */}
                <div className="events-fluid-container">
                    <div className="events-container">
                        <div className="events-title">Exercez-vous</div>
                        <div className="page-introduction">Evaluez dès maintenant vos connaissances bibliques en répondant aux questions ci-dessous.</div>
                        <Quiz/>               
                    </div>
                </div>
            </div>
        </div>
        );
}

export default QuizView;