import React, { Component } from 'react'
import './HomePage.css'
import ReactPlayer from 'react-player';
import FlipCountdown from '@rumess/react-flip-countdown';

import StickyMenu from '../StickyMenu/StickyMenu'
import EventsPage from '../EventsPage/EventsPage'


class HomePage extends Component{
    constructor(props){
        super(props)
    }

    render = () => {
        return(
            <div className="main-page-container">
                {/* Full width main picture 
                <div className="top-banner-container">
                    <div className="top-banner-image home-page-banner"/>
                    <div className="nested-title">
                        <div>Une équipe toujours prête à vous écouter</div>
                        <a class="rounded-button transparent-button" href="#/contact-us">contactez-nous</a>
                    </div>
                </div>*/}
                <div className="top-event-trailer-container">
                    <div className='player-wrapper'>
                        <ReactPlayer
                                url="https://www.lacroisadedelapriere.com/video/prions_ensemble.mp4"
                                className={'react-player-classname'}
                                playing={true}
                                controls={true}
                                light={"https://www.lacroisadedelapriere.com/picture/prions_light_snapshot.png"}
                                pip={true}
                                width={"100%"}
                                height={"100%"}
                            />
                    </div>
                </div>


                {/* Sticky menu bar */}
                <StickyMenu/>
                <div className="body-page-container">
                    {/* Intercessions quotidiennes 
                    <div className="events-fluid-container">
                        <div className="events-container">
                            <div className="events-title">Intercessions quotidiennes</div>
                            <EventsPage category={"daily"}/>
                        </div>
                    </div>*/}

                    <div className="help-us-container">
                        <div className='encart-rounded-gray'>
                            <p>
                                Ce monde a tant besoin de <b>prières !</b> <br/>
                                Ensemble, intercédons ensemble auprès du <b>Père</b> <br/>
                                Prions sans cesse !<br/>
                                <i>1 thessaloniciens 5.17</i>
                            </p>
                        </div>
                        <div className='help-us-buttons-container'>
                            <div>
                                <a class="rounded-button reverse-button" href="#/donate">Aidez-nous</a>
                            </div>

                            <div>
                                <a class="rounded-button reverse-button" href="#/contact-us">Contactez-nous</a>
                            </div>
                            
                            <div>
                                <a class="rounded-button reverse-button" target="_blank" href="https://www.jlrpt.com/priere">Priez pour nous</a>
                            </div>
                        </div>
                    </div>


                    {/* Intercessions spéciales */}
                    <div className="events-fluid-container">
                        <div className="events-container">
                            <div className="events-title">Edification & Intercessions</div>
                            <EventsPage category={"special"}/>
                        </div>
                    </div>


                    {/* Join us */}
                    <div className="joinus-container">
                        <div className="prayer-container">
                            <div className="week-prayer-container">
                                <div className="week-prayer img_week"></div>
                                <div className="prayer-title">
                                    Semaine de prière
                                </div>
                            </div>

                            <div className="evening-prayer-container">
                                <div className="evening-prayer img_nuit">
                                </div>
                                <div className="prayer-title">
                                    Soirée de prière
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="joinus-call-to-action-container">
                            <div className="joinus-call-to-action-title">
                                Rejoignez-nous
                            </div>

                            <div className="joinus-call-to-action-button-container">
                                <a className="rounded-button reverse-button" target="_blank" href="https://zoom.us/j/4826323228?pwd=K3NtaWV3dldYS1E4ZExzOS9xS0VhQT09">en direct</a>
                            </div>
                        </div>
                    </div>

                    <div className="countdown-container">
                        <div className="countdown-title">
                            Prochain évènement dans :
                        </div>
                        <div className ="countdown-timing">
                            <FlipCountdown
                                hideYear
                                hideMonth
                                yearTitle='Années'
                                monthTitle='Mois'
                                dayTitle='Jours'
                                hourTitle='Heures'
                                minuteTitle='Minutes'
                                secondTitle='Secondes'
                                endAt={'2022-04-30 18:00:00'} // Date/Time
                            />
                        </div>     
                    </div>

                    <div className="next-event-trailer-container">
                        <div className='player-wrapper'>
                            <p>Le ciel entier est en ébullition totale autour des préparatifs pour le retour glorieux de notre Seigneur <strong>Jésus-Christ</strong>.
                                Rendons cette année spéciale en permettant à Dieu d’œuvrer en nous pour accomplir ses desseins. 
                                Prêts à relever le <b>4<sup>ème</sup></b> challenge de l’année&nbsp;? Découvrez-le en vidéo&nbsp;!
                                Pour chaque challenge relevé, témoignons massivement en postant nos photos, nos vidéos, nos récits d’expériences ou autres supports à cette adresse : <a href="mailto:la.croisade.de.la.priere@gmail.com">la.croisade.de.la.priere@gmail.com.</a>
                            </p>
                        </div>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                //url="https://www.lacroisadedelapriere.com/video/cdlp_trailer.mp4"
                                //url="https://www.lacroisadedelapriere.com/video/challenge_video.mp4"
                                //url="https://www.lacroisadedelapriere.com/video/challenge_video_2022_01_30.mp4"
                                //url="https://www.lacroisadedelapriere.com/video/challenge_video_03.mp4"
                                url="https://www.lacroisadedelapriere.com/video/challenge_video_04.mp4"
                                //className='react-player'
                                playing={true}
                                controls={true}
                                light={"https://www.lacroisadedelapriere.com/picture/challenge_video_3.jpg"}
                                pip={true}
                                width='100%'
                            />
                            <div className="player-legend">Bande annonce - challenge #4 - Avril 2022</div>
                        </div>
                </div>
                </div>
            </div>
        )
    }
} 

export default HomePage;