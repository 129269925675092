import React from "react"
import EventsPage from "../Components/EventsPage/EventsPage"
import StickyMenu from "../Components/StickyMenu/StickyMenu"

const BiblePageView = () => {
    return(
        <div className="download-page-container">
            {/* Full width main picture */}
            <div className="top-banner-container">
                <div className="top-banner-image download-page-banner"/>
                <div className="nested-title">
                    <div>Matériel d'édificaton...</div>
                    <a class="rounded-button transparent-button" onClick={()=>{alert("Formulaire de contact, bientôt disponible !")}}>contactez-nous</a>
                </div>
            </div>
            {/* Sticky menu bar */}
            <StickyMenu/>
            
            <div className="body-page-container">
                {/* Evèvements à télécharger */}
                <div className="events-fluid-container">
                    <div className="events-container">
                        <div className="events-fluid-container">
                            <div className="events-container">
                                <div className="events-title">Fascicules disponibles</div>
                                <EventsPage category={"download"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BiblePageView;