import { Collapse } from '@material-ui/core';
import React, { useState } from 'react'
import "./StickyMenu.css"
import {globals} from "../Globals/Globals"

function StickyMenu(props) {
    let navbar_top_ref = React.createRef();
    let navbar_intitial_offset_top = -1;
    const debug = false;
    let [Toggle, setToggle] = useState(false);
    let [About, setAbout] = useState(false);
    let [Collapse, setCollapse] = useState(true);

    React.useEffect(
        () => {
            window.addEventListener('scroll', function () {
                if (navbar_top_ref.current) {
                    if (navbar_intitial_offset_top == -1)
                        navbar_intitial_offset_top = navbar_top_ref.current.offsetTop;

                    if (window.scrollY > navbar_intitial_offset_top) {
                        navbar_top_ref.current.classList.add('fixed-top');
                        // add padding top to show content behind navbar
                        let navbar_height = navbar_top_ref.current.offsetHeight;
                        document.body.style.paddingTop = navbar_height + 'px';
                    } else {
                        navbar_top_ref.current.classList.remove('fixed-top');
                        // remove padding top from body
                        document.body.style.paddingTop = '0';
                    }
                }
            });
        }
    )

    if (props.hiddenMenu)
        return(
            <>
            <nav ref={navbar_top_ref} id="navbar_top" class="navbar navbar-expand-lg navbar-dark bg-primary">
                <div class="container">
                    <div class="navbar-brand app-logo" href="#"></div>
                </div>
            </nav>
            </> 
        )
    else
    return (
        <>
            <nav ref={navbar_top_ref} id="navbar_top" class="navbar navbar-expand-lg navbar-dark bg-primary">
                <div class="container">
                    <a class="navbar-brand app-logo" href="#"></a>
                    <button onClick={() => { setCollapse(!Collapse); }}
                        class={`navbar-toggler ${Collapse ? "collapsed" : ""}`}
                        type="button" data-toggle="collapse" data-bs-target="#main_nav"
                        aria-expanded={`${Toggle ? "true" : "false"}`} aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class={`collapse navbar-collapse ${Collapse ? "collapse" : "collapse show"}`} id="main_nav">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item"><a class="nav-link" href="#">Accueil</a></li>
                            {/*
                            <li class="nav-item"><a class="nav-link" target="_blank" href="https://zoom.us/j/4826323228?pwd=K3NtaWV3dldYS1E4ZExzOS9xS0VhQT09"> En direct </a></li>*/}
                            <li class="nav-item"><a class="nav-link" target="_blank" href={`${debug ? "http://beta.jlrpt.com/" : "https://www.jlrpt.com"}`}> Requête de prières </a></li>
                            <li class="nav-item dropdown">
                                <a class={`nav-link dropdown-toggle ${Toggle ? "show" : ""}`} data-toggle="dropdown" onClick={() => {setAbout(false); setToggle(!Toggle); }}> Pour vous accompagner </a>
                                <ul class={`dropdown-menu dropdown-menu-right ${Toggle ? "show" : ""}`}>
                                    <li><a class="dropdown-item" href="#/bibleread">Lire la Bible</a></li>
                                    {globals.parameters.quiz_enabled &&
                                    <li><a class="dropdown-item" href="#/quiz">Quiz</a></li>}
                                    <li><a class="dropdown-item" href="#/download">Documents à télécharger</a></li>
                                    {/*
                                    <li><a class="dropdown-item" href="#" onClick={()=>{alert("Page vidéos, bientôt disponible !")}}>Vidéos</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={()=>{alert("Page de téléchargement, bientôt disponible !")}}>Documents à télécharger</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={()=>{alert("Page de e-books, bientôt disponible !")}}>Livres </a></li>*/}
                                    <li><a class="dropdown-item" href="#/contact-us">Nous contacter...</a></li>
                                    <li><a class="dropdown-item" href="#/promote-us">Partager...</a></li>
                                    <li><a class="dropdown-item" href="#/subscription">Adhérer...</a></li>
                                </ul>
                            </li>
                            {globals.parameters.donate_enabled &&
                                <a class="nav-link" href="#/donate">Faire un don</a>}
                            {/** About drop down menu */}
                            <li class="nav-item">
                                <a class={`nav-link cursor-pointer-fixe ${About ? "show" : ""}`} data-toggle="dropdown" onClick={() => { setToggle(false); setAbout(!About); }}> A propos de nous ... </a>
                                <ul class={`dropdown-menu dropdown-menu-right ${About ? "show" : ""}`}>
                                    <p class="about-panel">
                                        Association loi 1901<br />
                                        Créée pour transmettre la Parole vivante en intercédant <br/> par le <b>jeûne</b> et la <b>Prière</b>...
                                    </p>
                                </ul>
                            </li>
                        </ul>
                    </div> {/*<!-- navbar-collapse.// -->*/}
                    
                </div> {/*<!-- container-fluid.// -->*/}
            </nav>
        </>
    )
}

export default StickyMenu;