import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import '../HomePage/HomePage.css'

class ModalSubscriberSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      user_enabled : false,
      message:"Adhésion en cours d'enregistrement...",
      err:false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }),
    ()=>{if (this.state.modal) this.onSubscriberSubmitProcess();})
    ;
  }

  toastError = error_message => {
    console.log("toastError with error_message:",error_message)
    this.setState({message:error_message, err:true, user_enabled:true})
    if (this.props.onFailed)
      this.props.onFailed(error_message);
  }

  toastMessage = message => {
    console.log("toastMessage with error_message:",message)
    this.setState({message:message, err:false, user_enabled:true})
    if (this.props.onSuccess)
      this.props.onSuccess(message);
  }

  onSubscriberSubmitProcess  = async event => {
    const localURL = 'http://localhost:5100'
        const remoteURL = 'https://mailer.jesusestlareponsepourtous.org'
        let service_db_url = ["localhost", "127.0.0.1"].includes(window.location.hostname)?localURL:remoteURL

        console.log("micro-service => service_db_url:", service_db_url," this.props.data =>", this.props.data);
    
        //Populate contact table with new one
        var serviceResult = null
        var mailResult = null

        try{
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            serviceResult = await axios.post(`${service_db_url}/subscriber-add`,
            this.props.data);
        } catch(err){
            this.toastError(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    
        //Get service Acknowledge before resetting state values.
        console.log("subscriber-add returns :", serviceResult.data);
    
        //Send Acknoledge mail back to user
        if (serviceResult.data.state != true){
           this.toastError("Erreur:" + serviceResult.data.message);
        }
        else
        try{
                //Post confirmation e-mail back to user
                mailResult = await axios.post(`${service_db_url}/subscriber-acknowledge`,
                    {
                        from:"ne-pas-repondre@lacroisadedelapriere.org",
                        email:this.props.data.email,
                        subject:"Accusé de réception de votre demande d'adhésion",
                        prenom:this.props.data.prenom,
                        nom:this.props.data.nom,
                    }
                );

                //console.log("mailResult =>", mailResult);
                //Display confirmation number
                //this.toastMessage(`Un courriel de confirmation vous a été envoyé`)

                //Broadcast user mail message to jlrpt mailing staff
                try{
                    //Post confirmation e-mail back to user
                    const broadcastResult = await axios.post(`${service_db_url}/subscriber-mail-broadcast`,
                        {
                            from:"ne-pas-repondre@lacroisadedelapriere.org",
                            email:"jlrpt_contact_group@neuronal.bible",
                            email_user:this.props.data.email,
                            subject:"Demande d'adhésion postée depuis lacroisadedelapriere.com",
                            prenom:this.props.data.prenom,
                            nom:this.props.data.nom,
                            tel:this.props.data.tel,
                            message:(this.props.data.ismember==1)?"Adhésion d'un membre":"Adhésion d'un sympathisant"
                        }
                    );

                    console.log("broadcastResult =>", broadcastResult);
                    //Display confirmation number
                    console.log(`Courriel d'origine diffusé à l'équipe`)

                    //Clear form
                    this.toastMessage(`Un courriel de confirmation vous a été envoyé`)
                } catch(err){
                    this.toastError(`Une erreur est survenue :${err}`);
                    return;
                }
                /* Disabled
                //Clear form
                this.clearForm()*/
        } catch(err){
            this.toastError(`Une erreur est survenue :${err}`);
            return;
        }
}  


  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}></button>;
    return (
      <div id="modal-submit-2">
        {/* Published and visible button from parent window */}
        <Button 
          color={this.props.color} 
          className="cursor-pointer" 
          onClick={()=>{if (this.props.onCheckFormValidationBeforeSubmit()) {this.toggle()}}} 
          disabled={this.props.disabled}>{this.props.caption}</Button>
        {/* Modal window content */}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn} fade={false}>
          <ModalHeader>Enregistrement de la demande</ModalHeader>
          <ModalBody>
            {(this.state.err && this.state.user_enabled) && <h5 style={{color:'red'}}>Echec de soumission :</h5>}
            {(!this.state.err && this.state.user_enabled) && <h5 style={{color:`blue`}}>Soumission réussie :</h5>}
            {this.state.message}
          </ModalBody>
          <ModalFooter>
            {this.state.user_enabled &&
            <Button color="primary" onClick={this.toggle}>Ok</Button>}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalSubscriberSubmit;